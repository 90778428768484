import { debounce } from './helpers';
import { query } from './queries';

// Searchbar for judges
document.addEventListener('DOMContentLoaded', async (event) => {
  const judgeInput = document.querySelector('.judge-search-js') as HTMLInputElement;
  const dataList = document.querySelector('datalist#judge-search') as HTMLDataListElement;
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  if (judgeInput) {
    const suggestedNames = debounce(async function() {
      while (dataList.firstChild) {
        dataList.removeChild(dataList.firstChild)
      }

      const judges = await query(`/admins/judges/judge_approvals/suggested_names?name=${judgeInput.value}`, csrfToken);

      judges.map(judge => {
        const option = document.createElement('option');
        option.value = judge.first_name + " " + judge.last_name;
        dataList.appendChild(option);
      });
    }, 250);

    judgeInput.addEventListener('keyup', suggestedNames);
    judgeInput.addEventListener('change', suggestedNames);
  }
});