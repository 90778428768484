// Duplicate student modals messages
function currentDuplicateMessage(students) {
  return `
    Duplicate students with the name ${students[0].first_name} ${students[0].last_name} found.
    To attach nominations to existing students, visit their individual records located inside the
    "Students" tab on the sidebar.
  `;
}

function newDuplicateMessage(students) {
  return `
    A student with the name ${students[0].first_name} ${students[0].last_name} already exists.
    Would you like to attach this nomination to that student, or create a new student?
  `;
}

function newMultipleMessage(students) {
  return `
    Multiple students with the name ${students[0].first_name} ${students[0].last_name} already exist.
    To attach nominations to existing students, visit their individual records located inside the
    "Students" tab on the sidebar.
  `;
}

// Attach a record to an existing student
const attachRecordToStudent = (form, students, record) => {
  form.querySelector(`#${record}_student_id`).value = students[0].id;
  form.querySelector(`#${record}_first_name`).value = students[0].first_name;
  form.querySelector(`#${record}_last_name`).value = students[0].last_name;
  form.querySelector(`#${record}_grade`).value = students[0].grade;
  form.querySelector(`#${record}_gender`).value = students[0].gender;

  if (form.querySelector('#audition_feet') && students[0].feet) {
    form.querySelector('#audition_feet').value = students[0].feet;
  }

  if (form.querySelector('#audition_inches') && students[0].inches) {
    form.querySelector('#audition_inches').value = students[0].inches;
  }

  return form;
}

export { currentDuplicateMessage, newDuplicateMessage, newMultipleMessage, attachRecordToStudent }
