document.addEventListener('DOMContentLoaded', () => {
  const numberInputs = document.querySelectorAll('.number-input-js');

  // Ensure valid input for judge submissions
  document.addEventListener("mousewheel", function(event){
    let numberInput = (<HTMLInputElement>document.activeElement);
    if (numberInput.type === "number") {
      numberInput.blur();
    }
  });

  function zeroValueMessage() {
    return `
    One or more zero values detected.  Are you sure you want to submit this rubric?
    `;
  }

  const zeroValueModal = document.querySelector('.zero-value-modal-body') as HTMLElement;

  document.addEventListener('click', e => {
    const eventTarget = e.target as HTMLInputElement;

    if (eventTarget.id == 'button-score-submit-js') {
      const form = eventTarget.form;
      e.preventDefault();

      const inputs = Array.from(document.querySelectorAll('.number-input-js'));
      let count = 0;
      inputs.forEach(i => {
        let input = i as HTMLInputElement;
        if (<number><any>input.value == 0) {count++}
      });

      if (count > 0) {
        document.getElementById('zero-value-button').click();
        zeroValueModal.appendChild(document.createTextNode(zeroValueMessage()));
      } else {
        form.submit();
      }
    }

    if (eventTarget.id == 'zero-value') {
      const formParent = document.getElementById('button-score-submit-js') as HTMLInputElement;
      const form = formParent.form;
      form.submit();
    }
  });
});
