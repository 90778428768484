import { debounce } from '../helpers';
import { query } from '../queries';

document.addEventListener('DOMContentLoaded', async (event) => {
  const auditionNameInput = document.querySelector('.audition-search-js') as HTMLInputElement;
  const dataList = document.querySelector('datalist#audition-search') as HTMLDataListElement;
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  // Audition Searchbar
  if (auditionNameInput) {
    const school = auditionNameInput.dataset.schoolId;
    const suggestedNames = debounce(async function() {

      while (dataList.firstChild) {
        dataList.removeChild(dataList.firstChild)
      }

      const students = await query(`/educator/schools/${school}/students/suggested_auditions?name=${auditionNameInput.value}`, csrfToken);

      students.map(student => {
        const option = document.createElement('option');
        option.value = student.first_name + " " + student.last_name;
        dataList.appendChild(option);
      });
    }, 250);

    auditionNameInput.addEventListener('keyup', suggestedNames);
    auditionNameInput.addEventListener('change', suggestedNames);
  }
});
