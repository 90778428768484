import { festivalSearchBar } from '../searchbar'

// Festival Entry Searchbar queries
document.addEventListener('DOMContentLoaded', async (event) => {
  if (document.querySelector('.festival-search-js')) {
    const input = document.querySelector('.festival-search-js') as HTMLInputElement;
    const dataList = document.querySelector('datalist#festival-search') as HTMLDataListElement;
    const suggestedNames = e => festivalSearchBar(input, dataList, 'festival_entries');

    input.addEventListener('keyup', e => suggestedNames(e));
    input.addEventListener('change', e => suggestedNames(e));
  }

  if (document.querySelector('.selected_festival-search-js')) {
    const input = document.querySelector('.selected_festival-search-js') as HTMLInputElement;
    const dataList = document.querySelector('datalist#selected_festival-search') as HTMLDataListElement;
    const suggestedNames = e => festivalSearchBar(input, dataList, 'selected_entries');

    input.addEventListener('keyup', e => suggestedNames(e));
    input.addEventListener('change', e => suggestedNames(e));
  }

  if (document.querySelector('.missing_school-search-js')) {
    const input = document.querySelector('.missing_school-search-js') as HTMLInputElement;
    const dataList = document.querySelector('datalist#missing_school-search') as HTMLDataListElement;
    const suggestedNames = e => festivalSearchBar(input, dataList, 'missing_schools');

    input.addEventListener('keyup', e => suggestedNames(e));
    input.addEventListener('change', e => suggestedNames(e));
  }
});
