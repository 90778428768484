import { queryWithMethod } from './queries';

// Update participation status of a school
document.addEventListener('DOMContentLoaded', async () => {
  const participationSelectAll = document.querySelectorAll('.participation-school-js');

  participationSelectAll.forEach(box => {
    let checkbox = box as HTMLInputElement;

    checkbox.addEventListener('change', async (event) => {
      const target = event.target as HTMLInputElement;
      const school = target.dataset.school;
      const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

      if (checkbox.checked) {
        const events = await queryWithMethod(`/admins/schools/schools/${school}?participate=true`, csrfToken, 'put');

      } else {
        const events = await queryWithMethod(`/admins/schools/schools/${school}?participate=false`, csrfToken, 'put');
      }
    });
  });
});
