import * as React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe
} from 'react-stripe-elements';

interface Props {
  stripe?: any;
  path: string;
  styles: object;
}

interface State {
  isSending: boolean;
  isValid: boolean;
  isComplete: boolean;
  errorMessage: string;

  addedInputs: {
    emailReceipt: string;
  }

  validity: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
    postalCode: boolean;
    emailReceipt: boolean;
  }
}

class PaymentsParticipationForm extends React.Component<Props, State> {
  private csrfToken = null;
  private defaultErrorMessage = "There was an issue submitting your payment.";

  public state = {
    isSending: false,
    isValid: false,
    isComplete: false,
    errorMessage: null,
    addedInputs: {
      emailReceipt: "",
    },
    validity: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
      postalCode: false,
      emailReceipt: false,
    }
  };

  componentDidMount() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }

  onSubmit = (event) => {
    event.preventDefault();

    if (this.props.stripe) {
      this.setState(
        { isSending: true },
        async () => {
          try {
            const { token } = await this.props.stripe.createToken();
            const response = await fetch(this.props.path, {
              method: 'POST',
              body: JSON.stringify({
                source: token.id,
                email: this.state.addedInputs.emailReceipt,
              }),
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': this.csrfToken,
              }
            });

            if (response.status >= 500) throw new Error("There was an issue with our end. Please try again.")
            if (response.status >= 400) throw new Error(this.defaultErrorMessage)

            this.setState({ isSending: false, isComplete: true })
          } catch (error) {
            this.setState({
              isSending: false,
              isComplete: false,
              errorMessage: error['message'] || this.defaultErrorMessage,
            });
          }
        }
      );
    } else {
      this.setState({
        errorMessage: "There was an issue connecting to our payment provider. Please reload your page and try again."
      });
    }
  }

  onStripeChange = (event) => {
    const validity = {
      ...this.state.validity,
      [event.elementType]: event.complete && !event.error
    };

    this.setState({ validity });
  }

  onChange = ({ target }) => {
    const validity = {
      ...this.state.validity,
      [target.name]: target.validity.valid
    };

    const addedInputs = {
      ...this.state.addedInputs,
      [target.name]: target.value,
    }

    this.setState({ addedInputs, validity });
  }

  // Return true only if we've loaded stripe, have a valid card,
  // and we are not currently sending data.
  canSubmit = (): boolean => {
    const { validity, isSending } = this.state;
    const allValid = Object.keys(validity).map(key => validity[key]).every(Boolean)

    return !!this.props.stripe && allValid && !isSending;
  }

  renderForm = () => {
    return (
      <form className="form react-form" onSubmit={this.onSubmit}>
        <div className="form--split">
          <div className="form-group">
            <label className="d-block">
              Card Number
              <CardNumberElement
                onChange={this.onStripeChange}
                {...this.props.styles}
              />
            </label>
          </div>

          <div className="form-group">
            <label className="d-block">
              Expiration Date
              <CardExpiryElement
                onChange={this.onStripeChange}
                {...this.props.styles}
              />
            </label>
          </div>
        </div>
        <div className="form--split">
          <div className="form-group">
            <label className="d-block">
              CVC
              <CardCVCElement
                onChange={this.onStripeChange}
                {...this.props.styles}
              />
            </label>
          </div>

          <div className="form-group">
            <label className="d-block">
              Zip Code
              <PostalCodeElement
                onChange={this.onStripeChange}
                {...this.props.styles}
              />
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="d-block">
            Email Receipt To
            <input
              type="email"
              name="emailReceipt"
              placeholder="jane@example.com"
              value={this.state.addedInputs.emailReceipt}
              onChange={this.onChange}
              required
            />
          </label>
        </div>

        {this.state.errorMessage && (
          <p className="text-danger">
            {this.state.errorMessage}
          </p>
        )}

        <button
          type="submit"
          className="button"
          onClick={this.onSubmit}
          disabled={!this.canSubmit()}
        >Pay</button>
      </form>
    );
  }

  renderPaymentSuccess = () => {
    return (
      <h2>Payment Success!</h2>
    )
  }

  render() {
    if (this.state.isComplete) return this.renderPaymentSuccess();
    return this.renderForm();
  }
}

export default injectStripe(PaymentsParticipationForm);
