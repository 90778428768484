let setCheckboxesToFalse = checkboxes => {
  checkboxes.forEach(box => {
    let checkbox = box as HTMLInputElement;
    checkbox.checked = false;
  });
}

let sumEventFees = dataArray => {
  let amount = 0

  dataArray.forEach(record => amount += parseInt(record.fee));
  return amount;
}

document.addEventListener('DOMContentLoaded', async (event) => {
  const actions = document.getElementById('billing-actions')
  const checkboxes = document.querySelectorAll('.billing__checkbox_js');
  const selectAll = document.querySelector('.billing__all-checkboxes_js');
  let records = [];

  // set all checkboxes to false on page load/refresh
  // clear sessionStorage
  if (checkboxes.length > 0) {
    setCheckboxesToFalse(checkboxes);
    sessionStorage.clear();
    records.length = 0;

    // when a checkbox is checked, we store the student name, record id and fee, and checkbox location
    // when unchecked, we remove this information
    checkboxes.forEach((box, i) => {
      let checkbox = box as HTMLInputElement;

      checkbox.addEventListener('change', () => {
        if (checkbox.checked) {
          records.push({
            name: checkbox.dataset.student,
            id: checkbox.dataset.entry,
            fee: checkbox.dataset.fee,
            ensemble: checkbox.dataset.ensemble,
            type: checkbox.dataset.type,
            index: i
          });
        } else {
          records = records.filter(record => record.index != i);
        }

        records.length == 0 ? actions.classList.add('hidden') : actions.classList.remove('hidden');
      });
    });

    // select/un-select all checkboxes
    selectAll.addEventListener('click', e => {
      e.preventDefault();
      let allCheckboxes = selectAll as HTMLInputElement;
      if (allCheckboxes.innerText == "UNSELECT ALL") {

        records.length = 0;
        allCheckboxes.innerText = "SELECT ALL";
        actions.classList.add('hidden');

        checkboxes.forEach((box, i) => {
          let checkbox = box as HTMLInputElement;
          checkbox.checked = false;
        });

      } else {
        if (actions.classList.contains('hidden')) {
          allCheckboxes.innerText = "UNSELECT ALL";
          actions.classList.remove('hidden');

          checkboxes.forEach((box, i) => {
            let checkbox = box as HTMLInputElement;

            checkbox.checked = true;
            records.push({
              name: checkbox.dataset.student,
              id: checkbox.dataset.entry,
              fee: checkbox.dataset.fee,
              ensemble: checkbox.dataset.ensemble,
              type: checkbox.dataset.type,
              index: i
            });
          });

        } else {
          checkboxes.forEach((box, i) => {
            let checkbox = box as HTMLInputElement;
            allCheckboxes.innerText = "UNSELECT ALL";

            if (checkbox.checked == false) {
              checkbox.checked = true;
              records.push({
                name: checkbox.dataset.student,
                id: checkbox.dataset.entry,
                fee: checkbox.dataset.fee,
                ensemble: checkbox.dataset.ensemble,
                type: checkbox.dataset.type,
                index: i
              });
            }
          });
        }
      }
    });
  }

  // clicking 'Next' will take us to the payment form page, with all student and record data
  // placed into session storage
  if (document.querySelector('.billing__next_js')) {
    document.querySelector('.billing__next_js').addEventListener('click', () => {
      sessionStorage.setItem("eventData", JSON.stringify(records));
    });
  }

  if (document.querySelector('.billing__clear_js')) {
    document.querySelector('.billing__clear_js').addEventListener('click', () => {
      let allCheckboxes = selectAll as HTMLInputElement;

      records.length = 0;
      allCheckboxes.innerText = "Select All";
      actions.classList.add('hidden');

      checkboxes.forEach((box, i) => {
        let checkbox = box as HTMLInputElement;
        checkbox.checked = false;
      });
    });
  }

  // display selected students and record fees
  if (document.querySelector('.event__entries_js')) {
    const dataArray = JSON.parse(sessionStorage.getItem("eventData"));
    const fee = sumEventFees(dataArray);

    document.querySelector('.event__entries_js').innerHTML = dataArray.map(record => " " + record.name);
    document.querySelector('.event__fees_js').innerHTML = `$${fee.toString()}.00`;
  }
});
