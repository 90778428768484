document.addEventListener('DOMContentLoaded', async (event) => {

  // Valid inputs for judging rubrics
  let rubricItems = Array.from( document.querySelectorAll( '.number-input-js' ) );
  let rubricItemsCount = rubricItems.length;


  let checkInputValue = (inputValue, element) => {
    // if input value has a decimal but no numbers after continue
    let crazyInput = inputValue % 1;

    if (crazyInput) {
      element.value = inputValue.toString();
      return inputValue;
    } else {
      return inputValue;
    }
  }

  rubricItems.forEach(item => {
    item as HTMLInputElement;

    item.addEventListener('change', function( e: KeyboardEvent) {
      if (e.code == 'Enter') {
        e.preventDefault();
        return;
      }
    });

    item.addEventListener('keydown', function( e: KeyboardEvent) {
      if (String(e.code) == 'Enter') {
        e.preventDefault();
        return;
      }
    });

    item.addEventListener('keyup', function( e: KeyboardEvent) {
      if (e.code == 'Enter') {
        e.preventDefault();
        return;
      }

      let element = item as HTMLInputElement;
      let elementValue = parseFloat(element.value).toFixed(2);
      elementValue = checkInputValue(elementValue, element);
      let siblingElement = parseFloat((this.parentNode.nextElementSibling.innerHTML).replace(/[/!@#$%^&*]\s/g, "")).toFixed(2);

      if ((Number(elementValue) >= 0) && Number(elementValue) <= Number(siblingElement)) {
        console.log(rubricItems.filter((input): input is HTMLInputElement => (input as HTMLInputElement).value === ""));
        if (rubricItems.filter((input): input is HTMLInputElement => (input as HTMLInputElement).value === "").length == 0) {
          document.querySelector( `.button--score-submit` ).setAttribute( 'class', 'button button--score-submit' );
        } else {
          document.querySelector( `.button--score-submit` ).setAttribute( 'class', 'button button--score-submit button-disabled-js disable-click' );
        }
      } else if((Number(elementValue) >= 0) && Number(elementValue) > Number(siblingElement)) {
        document.querySelector( `.button--score-submit` ).setAttribute( 'class', 'button button--score-submit button-disabled-js disable-click' );
      } else {
        document.querySelector( `.button--score-submit` ).setAttribute( 'class', 'button button--score-submit button-disabled-js disable-click' );
      }
    });
  });
});
