document.addEventListener('change', async (event) => {
  const target = event.target as HTMLInputElement;

  // Assign Judges to a Section
  if (target.getAttribute('data-action') === 'section-judge-select') {
    const judge = target.value;
    const rubric = target.getAttribute('data-rubric');
    const section = target.getAttribute('data-section');

    if (!judge) { return }

    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    const judge_name = await fetch(`/admins/judges/district_rubrics/assign_section_judges?id=${judge}&rubric_id=${rubric}&section_id=${section}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      }
    })
      .then(res => res.json())
      .then(res => res.data)

    location.reload();
  }

  // Assign Judges to a Sub Section
  if (target.getAttribute('data-action') === 'sub-section-judge-select') {
    const judge = target.value;
    const rubric = target.getAttribute('data-rubric');
    const subSection = target.getAttribute('data-sub-section');

    if (!judge) { return }

    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    const judge_name = await fetch(`/admins/judges/district_rubrics/assign_sub_section_judges?id=${judge}&rubric_id=${rubric}&sub_section_id=${subSection}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      }
    })
      .then(res => res.json())
      .then(res => res.data)

    location.reload();
  }
});
