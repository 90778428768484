import * as React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe
} from 'react-stripe-elements';

interface Props {
  stripe?: any;
  path: string;
  styles: object;
}

interface State {
  isSending: boolean;
  isValid: boolean;
  isComplete: boolean;
  errorMessage: string;

  addedInputs: {
    emailReceipt: string;
    amount: number;
    records: string[];
  }

  validity: {
    cardNumber: boolean;
    cardExpiry: boolean;
    cardCvc: boolean;
    postalCode: boolean;
    emailReceipt: boolean;
  }
}

class BillingForm extends React.Component<Props, State> {
  private csrfToken = null;
  private defaultErrorMessage = "There was an issue submitting your payment.";

  public state = {
    isSending: false,
    isValid: false,
    isComplete: false,
    errorMessage: null,
    addedInputs: {
      emailReceipt: "",
      amount: 0,
      records: [],
    },
    validity: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
      postalCode: false,
      emailReceipt: false,
    }
  };

  componentDidMount() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.setState({
      addedInputs: {
        emailReceipt: "",
        amount: this.sumEventFees(this.dataArray),
        records: this.dataArray
      }
    });
  }

  sumEventFees = dataArray => {
    let amount = 0

    dataArray.forEach(record => amount += parseInt(record.fee));
    return amount;
  }

  dataArray = JSON.parse(sessionStorage.getItem("eventData"));

  onSubmit = (event) => {
    event.preventDefault();

    if (this.props.stripe) {
      this.setState(
        { isSending: true },
        async () => {
          try {
            const { token } = await this.props.stripe.createToken();
            const response = await fetch(this.props.path, {
              method: 'POST',
              body: JSON.stringify({
                source: token.id,
                email: this.state.addedInputs.emailReceipt,
                amount: this.state.addedInputs.amount,
                records: this.state.addedInputs.records
              }),
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': this.csrfToken,
              }
            });
            if (response.status >= 500) throw new Error("There was an issue with our end. Please try again.")
            if (response.status >= 400) throw new Error(this.defaultErrorMessage)

            this.setState({ isSending: false, isComplete: true })
          } catch (error) {
            this.setState({
              isSending: false,
              isComplete: false,
              errorMessage: error['message'] || this.defaultErrorMessage,
            });
          }
        }
      );
    } else {
      this.setState({
        errorMessage: "There was an issue connecting to our payment provider. Please reload your page and try again."
      });
    }
  }

  onStripeChange = (event) => {
    const validity = {
      ...this.state.validity,
      [event.elementType]: event.complete && !event.error
    };

    this.setState({ validity });
  }

  onChange = ({target}) => {
    const validity = {
      ...this.state.validity,
      [target.name]: target.validity.valid
    };

    const addedInputs = {
      ...this.state.addedInputs,
      [target.name]: target.value,
    }

    this.setState({ addedInputs, validity });
  }

  // Return true only if we've loaded stripe, have a valid card,
  // and we are not currently sending data.
  canSubmit = (): boolean => {
    const { validity, isSending } = this.state;
    const allValid = Object.keys(validity).map(key => validity[key]).every(Boolean)

    return !!this.props.stripe && allValid && !isSending;
  }

  renderForm = () => {
    return (
      <form className="form react-form" onSubmit={this.onSubmit}>
        <input
          type="hidden"
          name="amount"
          value={this.state.addedInputs.amount}
        />

        <input
          type="hidden"
          name="records"
          value={this.state.addedInputs.records}
        />
        <div className="form--split">
        <div className="form-group">
          <label className="d-block">
            CARD NUMBER
            <CardNumberElement
              onChange={this.onStripeChange}
              {...this.props.styles}
            />
          </label>
        </div>

        <div className="form-group">
          <label className="d-block">
            EXPIRATION DATE
            <CardExpiryElement
              onChange={this.onStripeChange}
              {...this.props.styles}
            />
          </label>
        </div>
        </div>
        <div className="form--split">
        <div className="form-group">
          <label className="d-block">
            CVC
            <CardCVCElement
              onChange={this.onStripeChange}
              {...this.props.styles}
            />
          </label>
        </div>

        <div className="form-group">
          <label className="d-block">
            ZIP CODE
            <PostalCodeElement
              onChange={this.onStripeChange}
              {...this.props.styles}
            />
          </label>
        </div>
        </div>
        <div className="form-group">
          <label className="d-block">
            EMAIL RECEIPT TO
            <input
              type="email"
              name="emailReceipt"
              placeholder="Name@schoolemail.com"
              value={this.state.addedInputs.emailReceipt}
              onChange={this.onChange}
              required
            />
          </label>
        </div>

        {this.state.errorMessage && (
          <p className="text-danger">
            {this.state.errorMessage}
          </p>
        )}

        <button
          type="submit"
          className="button"
          onClick={this.onSubmit}
          disabled={!this.canSubmit()}
        >Submit Payment</button>
      </form>
    );
  }

  renderPaymentSuccess = () => {
    return (
      <h2>Payment Success!</h2>
    )
  }

  render() {
    if (this.state.isComplete) return this.renderPaymentSuccess();
    return this.renderForm();
  }
}

export default injectStripe(BillingForm);
