// Searchbar queries
let debounce = (func, wait, immediate=null) => {
  let timeout;

  return function() {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

// assign attributes from form values
let createStudentFromForm = (form, record) => {
  if (!form.querySelector(`#${record}_first_name`)) { return null }

  let student = {
    firstName: (form.querySelector(`#${record}_first_name`) as HTMLInputElement).value,
    lastName: (form.querySelector(`#${record}_last_name`) as HTMLInputElement).value,
    grade: (form.querySelector(`#${record}_grade`) as HTMLInputElement).value,
    gender: (form.querySelector(`#${record}_gender`) as HTMLInputElement).value,
  }

  return student;
}

// Check for duplicate records
let studentsMatch = (student, alt_student) => {
  if (student.firstName === alt_student.firstName && student.lastName === alt_student.lastName &&
      student.gender === alt_student.gender && student.grade === alt_student.grade) {
    return true;
  } else {
    return false;
  }
}

export { debounce, createStudentFromForm, studentsMatch }
