import { query } from './queries';

// List a district's schools
document.addEventListener('change', async (event) => {
  const target = event.target as HTMLInputElement;

  if (target.getAttribute('data-action') === 'district-select') {
    const districtId = target.value;
    const dataTarget = target.getAttribute('data-target');
    const el: HTMLSelectElement = document.querySelector(dataTarget);

    if (!el) return console.error('Invalid target provided for district-select');

    el.innerHTML = null;

    if (!districtId) {
      el.disabled = true;
      return;
    }

    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    const schools = await query(`/districts/${districtId}/schools`, csrfToken);

    // blank by default
    const option = document.createElement('option');
    option.value = "";
    option.innerText = "Select School";
    el.appendChild(option);

    schools.map(school => {
      const option = document.createElement('option');
      option.value = school[1];
      option.innerText = `${school[0]} - ${school[2]}`;
      el.appendChild(option);
    });

    el.disabled = false;
  }
});
