import { queryWithMethod } from './queries';

// Activate/De-activate a school
document.addEventListener('DOMContentLoaded', async () => {
  const activeSelectAll = document.querySelectorAll('.active-school-js');

  activeSelectAll.forEach(box => {
    let checkbox = box as HTMLInputElement;

    checkbox.addEventListener('change', async (event) => {
      const target = event.target as HTMLInputElement;
      const school = target.dataset.school;
      const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

      if (checkbox.checked) {
        const events = await queryWithMethod(`/admins/schools/schools/${school}?checked=true`, csrfToken, 'put');

      } else {
        const events = await queryWithMethod(`/admins/schools/schools/${school}?checked=false`, csrfToken, 'put');
      }
    });
  });
});
