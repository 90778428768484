import { debounce } from './helpers';
import { query } from './queries';

// Admin Student searchbar
document.addEventListener('DOMContentLoaded', async (event) => {
  const adminStudentNameInput = document.querySelector('.admin_student-search-js') as HTMLInputElement;
  const dataList = document.querySelector('datalist#admin_student-search') as HTMLDataListElement;
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  if (adminStudentNameInput) {
    const suggestedNames = debounce(async function() {
      while (dataList.firstChild) {
        dataList.removeChild(dataList.firstChild)
      }

      const students = await query(`/admins/students/students/suggested_names?name=${adminStudentNameInput.value}`, csrfToken);

      students.map(student => {
        const option = document.createElement('option');
        option.value = student.first_name + " " + student.last_name;
        dataList.appendChild(option);
      });
    }, 250);

    adminStudentNameInput.addEventListener('keyup', suggestedNames);
    adminStudentNameInput.addEventListener('change', suggestedNames);
  }
});