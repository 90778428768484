import { query } from '../queries';

document.addEventListener('change', async (event) => {
  const target = event.target as HTMLInputElement;
  const validActions = ['pilot-event-select'];

  // Retrieve and set ensemble, grade, and type
  if (validActions.includes(target.getAttribute('data-action'))) {
    // const districtId = (<HTMLInputElement>document.getElementById('nomination_district_id')).value;
    // const ensemble_input = document.getElementById('audition_ensemble');
    const dataTarget = target.getAttribute('data-target');
    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    let custom_event = null;

    if (target.getAttribute('data-action') === 'pilot-event-select') {
      const el: HTMLSelectElement = document.querySelector(dataTarget);
      custom_event = target.value;

      if (!el) return console.error(`Invalid target provided for ${target.getAttribute('data-action')}`);
      el.innerHTML = null;

      if (!custom_event) {
        el.disabled = true;
        return;
      }

      let parts = null;

      parts = await query(`/educator/schools/1/pilot_events/eligible_parts?custom_event=${custom_event}`, csrfToken);
      // blank by default
      const option = document.createElement('option');
      option.value = "";
      option.innerText = "Please select a part...";
      el.appendChild(option);

      parts.map(part => {
        const option = document.createElement('option');
        option.value = part[1];
        option.innerText = part[0];
        el.appendChild(option);
      });

      el.disabled = false;
    }
  }
});
