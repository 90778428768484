import { queryWithMethod } from '../queries';

document.addEventListener('change', async (event) => {
  const target = event.target as HTMLInputElement;

  // Update an All-State entry with seating/ranking values
  if (target.getAttribute('data-action') === 'all-state-seating') {
    const event = target.value;
    const all_state_id = target.dataset.audition;
    const update_value = target.value;
    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

    if (target.id === "all_state_entry_ranking") {
      const events = await queryWithMethod(`/admins/all_state_seatings/auditions/${all_state_id}?ranking=${update_value}`, csrfToken, 'put');
    }

    if (target.id === "all_state_entry_seating") {
      const events = await queryWithMethod(`/admins/all_state_seatings/auditions/${all_state_id}?seating=${update_value}`, csrfToken, 'put');
    }
  };

  if (target.getAttribute('data-action') === 'festival-ranking') {
    const event = target.value;
    const festival_id = target.dataset.entry;
    const update_value = target.value;
    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

    if (target.id === "festival_entry_ranking") {
      const events = await queryWithMethod(`/admins/all_state/rankings/${festival_id}?ranking=${update_value}`, csrfToken, 'put');
    }
  };
});
