let resetCheckboxes = checkboxes => {
  checkboxes.forEach(box => {
    let checkbox = box as HTMLInputElement;
    checkbox.checked = false;
  });
}

document.addEventListener('DOMContentLoaded', async (event) => {
  const actions = document.getElementById('mailer-actions')
  const checkboxes = document.querySelectorAll('.mailer__checkbox_js');
  const selectAll = document.querySelector('.mailer__all-checkboxes_js');
  const educatorSelectAll = document.querySelector('.educator-mailer__all-checkboxes_js');
  let records = [];

  // set all checkboxes to false on page load/refresh
  if (checkboxes.length > 0) {
    resetCheckboxes(checkboxes);
    records.length = 0;

    // when a checkbox is checked, we store the email
    // when unchecked, we remove this information
    // This will not persist across pages, will need session storage for that
    checkboxes.forEach((box, i) => {
      let checkbox = box as HTMLInputElement;

      checkbox.addEventListener('change', () => {
        if (checkbox.checked) {
          records.push({
            email: checkbox.dataset.email,
            index: i
          });
        } else {
          records = records.filter(record => record.index != i);
        }

        records.length == 0 ? actions.classList.add('hidden') : actions.classList.remove('hidden');
      });
    });

    // select/un-select all checkboxes
    if (selectAll) {
      selectAll.addEventListener('click', () => {
        let allCheckboxes = selectAll as HTMLInputElement;
        const mail = document.createElement("a");

        mail.href = `/admins/admins/contacts/new?emails=${allCheckboxes.dataset.emails.toString()}`
        mail.click();
      });
    }

    // educator mail all
    if (educatorSelectAll) {
      educatorSelectAll.addEventListener('click', () => {
        let allEmails = educatorSelectAll as HTMLInputElement;
        const mail = document.createElement("a");

        mail.href = `/admins/educators/contacts/new?emails=${allEmails.dataset.emails.toString()}`
        mail.click();
      });
    }
  }

  // custom mailer form for educators
  if (document.querySelector('.mailer__mail_js')) {
    document.querySelector('.mailer__mail_js').addEventListener('click', () => {

      const mail = document.createElement("a");
      mail.href = `/admins/educators/contacts/new?emails=${records.map(record => record.email).toString()}`;
      mail.click();
    });
  }

  // custom mailer form for admins
  if (document.querySelector('.admin__mailer__mail_js')) {
    document.querySelector('.admin__mailer__mail_js').addEventListener('click', () => {

      const mail = document.createElement("a");
      mail.href = `/admins/admins/contacts/new?emails=${records.map(record => record.email).toString()}`;
      mail.click();
    });
  }

  // Reset selections
  if (document.querySelector('.mailer__clear_js')) {
    document.querySelector('.mailer__clear_js').addEventListener('click', () => {
      let allCheckboxes = selectAll as HTMLInputElement;
      records.length = 0;
      allCheckboxes.innerText = "Select All";
      actions.classList.add('hidden');

      checkboxes.forEach((box, i) => {
        let checkbox = box as HTMLInputElement;
        checkbox.checked = false;
      });
    });
  }

  // Reset selections
  if (document.querySelector('.admin__mailer__clear_js')) {
    document.querySelector('.admin__mailer__clear_js').addEventListener('click', () => {
      let allCheckboxes = selectAll as HTMLInputElement;
      records.length = 0;
      allCheckboxes.innerText = "Select All";
      actions.classList.add('hidden');

      checkboxes.forEach((box, i) => {
        let checkbox = box as HTMLInputElement;
        checkbox.checked = false;
      });
    });
  }
});
