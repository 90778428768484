import { debounce } from './helpers';
import { query } from './queries';

// Searchbar for school names
document.addEventListener('DOMContentLoaded', async (event) => {
  const nameInput = document.querySelector('.school-search-js') as HTMLInputElement;
  const dataList = document.querySelector('datalist#school-search') as HTMLDataListElement;
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  if (nameInput) {
    const suggestedNames = debounce(async function() {
      while (dataList.firstChild) {
        dataList.removeChild(dataList.firstChild)
      }

      const schools = await query(`/admins/schools/schools/suggested_names?name=${nameInput.value}`, csrfToken);

      schools.map(school => {
        const option = document.createElement('option');
        option.value = school.name;
        dataList.appendChild(option);
      });
    }, 250);

    nameInput.addEventListener('keyup', suggestedNames);
    nameInput.addEventListener('change', suggestedNames);
  }
});
