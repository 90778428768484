import { createStudentFromForm, studentsMatch } from './helpers';
import { currentDuplicateMessage, newDuplicateMessage, newMultipleMessage, attachRecordToStudent } from './modals';
import { query } from './queries';

function executeSubmitButton(submitButton, record) {
  let eventTarget, form, school, student, currentStudent = null;
  const currentForm = document.querySelector(`.edit_${record}`);
  const schoolIdInput = document.querySelector('.hidden-school-id-js') as HTMLInputElement;

  // current student values
  if (currentForm) {
    currentStudent = createStudentFromForm(currentForm, record);
  }

  // Grab student values from form
  submitButton.addEventListener('click', e => {
    eventTarget = e.target as HTMLInputElement;
    form = eventTarget.form;

    if (schoolIdInput) {
      school = form.querySelector(`#${record}_school_selection_id`) as HTMLInputElement;
    } else {
      school = form.querySelector(`#${record}_school_id`) as HTMLInputElement;
    }

    student = createStudentFromForm(form, `${record}`);

    if (schoolIdInput) {
      schoolIdInput.value = school.value;
    }
  });

  document.addEventListener('submit', async (e) => {
    eventTarget = e.target as HTMLInputElement;

    // Deleting records does not need additional checks
    if (eventTarget.querySelector("[value='delete']")) {
      return;
    }

    // Perform logic for all submissions unless logging out
    if (!eventTarget.action.includes('auth/logout')) {
      e.preventDefault();
    }

    // Bypass checks for unedited records, allow height to be edited
    if (currentStudent) {
      if (studentsMatch(student, currentStudent)) {
        form.submit();
        return;
      }
    }

    // Allow the form to fail on the backend without AJAX call to #suggested_students
    if (!student.firstName || !student.lastName) {
      form.submit();
      return;

    } else {
      // Check if similar student exists
      const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
      const students = await query(`/educator/schools/${school.value}/students/suggested_students?first_name=${student.firstName}&last_name=${student.lastName}&grade=${student.grade}&gender=${student.gender}`, csrfToken);

      // Modals alert user to duplicate students
      const singleStudentModal = document.querySelector('.single-student-modal-body') as HTMLElement;
      const multiStudentModal = document.querySelector('.multi-student-modal-body') as HTMLElement;
      const multiStudentEditModal = document.querySelector('.multi-student-edit-modal-body') as HTMLElement;

      if (currentStudent) {
        if (students.length > 0) {
          document.getElementById('multi-student-edit-nomination-button').click();
          multiStudentEditModal.appendChild(document.createTextNode(currentDuplicateMessage(students)));
        }

        else {
          form.submit();
        }
      } else {
        if (students.length === 1) {
          document.getElementById('single-student-nomination-button').click();
          singleStudentModal.appendChild(document.createTextNode(newDuplicateMessage(students)));

        } else if (students.length > 1) {
          document.getElementById('multi-student-nomination-button').click();
          multiStudentModal.appendChild(document.createTextNode(newMultipleMessage(students)));

        } else {
          form.submit();
        }
      }

      document.addEventListener('click', e => {
        const eventTarget = e.target as HTMLInputElement;

        // Create a new student, ignore existing student
        if (eventTarget.id === 'new-student') {
          form.submit();

          // Attach nomination to existing student
        } else if (eventTarget.id == 'existing-student') {
          attachRecordToStudent(form, students, record);
          form.submit();
        }
      });
    }
  });
}

export { executeSubmitButton }
