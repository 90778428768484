document.addEventListener('DOMContentLoaded', async (event) => {

  const sidebar = document.querySelector('.admin-dash');
  const sidebarSlide = document.querySelector('.dashboard_block--left');
  const contentSlide = document.querySelector('.dashboard_block--right');
  const hamburger = document.querySelector('.hamburger-menu');

  if(sidebar) {
  sidebar.addEventListener('click', function(e) {
    let el = e.target as Element;
    if(el.classList.contains('nav-head--wrapper') && el.classList.contains('active')) {
      el.classList.remove('active');
    } else if(el.classList.contains('nav-head--wrapper') && !el.classList.contains('active') ) {
      sidebar.querySelectorAll('.active').forEach( e=> e.classList.remove('active') );
      el.classList.add('active')
    }
  });
}

  if(hamburger) {
    hamburger.addEventListener('click', function() {

      if(sidebarSlide.classList.contains('active')) {
        sidebarSlide.classList.remove('active');
        contentSlide.classList.remove('open-slide');
      } else {
        sidebarSlide.classList.add('active');
        contentSlide.classList.add('open-slide');
      }
    })
  }
});