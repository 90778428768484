import { queryWithMethod } from './queries';

// Toggle attendance for festival entries
document.addEventListener('DOMContentLoaded', async () => {
  const attendedFestival = document.querySelectorAll('.attended-festival-js');

  attendedFestival.forEach(box => {
    let checkbox = box as HTMLInputElement;
    checkbox.addEventListener('change', async (event) => {
      const target = event.target as HTMLInputElement;
      const ensemble = target.dataset.ensemble;
      const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

      if (checkbox.checked) {
        const events = await queryWithMethod(`/admins/all_state/ensembles/${ensemble}/alter_attendance?checked=true`, csrfToken, 'put');
      } else {
        const events = await queryWithMethod(`/admins/all_state/ensembles/${ensemble}/alter_attendance?checked=false`, csrfToken, 'put');
      }

      location.reload();
    });
  });
});
