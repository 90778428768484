import { allStateSearchBar } from '../searchbar'

// Searchbar suggestions for All-State views, grouped by queries
document.addEventListener('DOMContentLoaded', async (event) => {
  if (document.querySelector('.composition-search-js')) {
    const input = document.querySelector('.composition-search-js') as HTMLInputElement;
    const dataList = document.querySelector('datalist#composition-search') as HTMLDataListElement;
    const suggestedNames = e => allStateSearchBar(input, dataList, 'composition');

    input.addEventListener('keyup', e => suggestedNames(e));
    input.addEventListener('change', e => suggestedNames(e));
  }

  if (document.querySelector('.elementary_all_state-search-js')) {
    const input = document.querySelector('.elementary_all_state-search-js') as HTMLInputElement;
    const dataList = document.querySelector('datalist#elementary_all_state-search') as HTMLDataListElement;
    const suggestedNames = e => allStateSearchBar(input, dataList, 'elementary_all_state');

    input.addEventListener('keyup', e => suggestedNames(e));
    input.addEventListener('change', e => suggestedNames(e));
  }

  if (document.querySelector('.ensemble-search-js')) {
    const input = document.querySelector('.ensemble-search-js') as HTMLInputElement;
    const dataList = document.querySelector('datalist#ensemble-search') as HTMLDataListElement;
    const suggestedNames = e => allStateSearchBar(input, dataList, 'ensemble');

    input.addEventListener('keyup', e => suggestedNames(e));
    input.addEventListener('change', e => suggestedNames(e));
  }

  if (document.querySelector('.future_educator-search-js')) {
    const input = document.querySelector('.future_educator-search-js') as HTMLInputElement;
    const dataList = document.querySelector('datalist#future_educator-search') as HTMLDataListElement;
    const suggestedNames = e => allStateSearchBar(input, dataList, 'future_educator');

    input.addEventListener('keyup', e => suggestedNames(e));
    input.addEventListener('change', e => suggestedNames(e));
  }

  if (document.querySelector('.seated_entry-search-js')) {
    const input = document.querySelector('.seated_entry-search-js') as HTMLInputElement;
    const dataList = document.querySelector('datalist#seated_entry-search') as HTMLDataListElement;
    const suggestedNames = e => allStateSearchBar(input, dataList, 'seated_entrie', 'seatings');

    input.addEventListener('keyup', e => suggestedNames(e));
    input.addEventListener('change', e => suggestedNames(e));
  }

  if (document.querySelector('.selected_entry-search-js')) {
    const input = document.querySelector('.selected_entry-search-js') as HTMLInputElement;
    const dataList = document.querySelector('datalist#selected_entry-search') as HTMLDataListElement;
    const suggestedNames = e => allStateSearchBar(input, dataList, 'selected_entrie');

    input.addEventListener('keyup', e => suggestedNames(e));
    input.addEventListener('change', e => suggestedNames(e));
  }
});

