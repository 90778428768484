import * as React from 'react';

import StripeWrapper from './stripe/Wrapper';
import StripeStyles from './stripe/styles';
import BillingForm from './stripe/BillingForm';

interface Props {
  path: string;
}

class SchoolPayment extends React.Component<Props> {
  render() {
    return (
      <StripeWrapper>
        <div className="card detailed card--form">
          <BillingForm styles={StripeStyles} path={this.props.path} />
        </div>
      </StripeWrapper>
    );
  }
}

export default SchoolPayment;
