import { queryWithMethod } from './queries';

// Select/Unselect judge parts
document.addEventListener('DOMContentLoaded', async (event) => {
  const parts = document.querySelectorAll('.judges-part-js');
  const districtSelect = document.querySelector('.judges-part-district-select-js') as HTMLSelectElement;
  parts.forEach(part => {
    let checkbox = part as HTMLElement;

    checkbox.addEventListener('click', async (event) => {
      const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
      if (checkbox.dataset.distRubricSection) {
        const judgePart = await queryWithMethod(`/admins/judges/judges_parts/manage_parts?id=${checkbox.dataset.user}&part=${checkbox.dataset.part}&district=${checkbox.dataset.district}&dist_rubric_section=${checkbox.dataset.distRubricSection}`, csrfToken, 'post');
      } else if (checkbox.dataset.distRubricSubSection) {
        const judgePart = await queryWithMethod(`/admins/judges/judges_parts/manage_parts?id=${checkbox.dataset.user}&part=${checkbox.dataset.part}&district=${checkbox.dataset.district}&dist_rubric_sub_section=${checkbox.dataset.distRubricSubSection}`, csrfToken, 'post');
      } else {
        const judgePart = await queryWithMethod(`/admins/judges/judges_parts/manage_parts?id=${checkbox.dataset.user}&part=${checkbox.dataset.part}&district=${checkbox.dataset.district}`, csrfToken, 'post');
      }
    });
  });
});
