document.addEventListener('DOMContentLoaded', async (event) => {

   /*
      1. Needs to grab the dropdown element to reference
      2. Records the click - verifies the click and checks it against the dropdown element
      3. checks if the element clicked is the dropdown that is clicked.
      4. if clicked outside of the dropdown element collapses the dropdown.
      */

  const addStudentDropdown = document.querySelector('.dashboard_block--subheader.add-student .nav--dropdown');

  if(addStudentDropdown) {
    document.addEventListener('click', function(droppyDown) {
      let studentDropdown = addStudentDropdown as Element;
      let dropdownTarget = droppyDown.target as Element;
      let dropdownTargetParent = dropdownTarget.parentNode;

      if (dropdownTargetParent !== studentDropdown && studentDropdown.classList.contains('active')) {
        studentDropdown.classList.remove('active');
      } else
      if (dropdownTargetParent === studentDropdown && studentDropdown.classList.contains('active')) {
        studentDropdown.classList.remove('active');
      } else
      if ( dropdownTargetParent === studentDropdown && !studentDropdown.classList.contains('active')) {
        studentDropdown.classList.add('active');
      }
    })
  }
});