import { executeSubmitButton } from './forms';

// Validate student and form inputs, submit form
document.addEventListener('DOMContentLoaded', async (event) => {
  if (document.querySelector('.audition-submit-js')) {
    executeSubmitButton(document.querySelector('.audition-submit-js'), 'audition');

  } else if (document.querySelector('.composition-submit-js')) {
    executeSubmitButton(document.querySelector('.composition-submit-js'), 'composition');

  } else if (document.querySelector('.elementary-all-state-submit-js')) {
    executeSubmitButton(document.querySelector('.elementary-all-state-submit-js'), 'elementary_all_state');

  } else if (document.querySelector('.future-educator-submit-js')) {
    executeSubmitButton(document.querySelector('.future-educator-submit-js'), 'future_educator');
  }
});