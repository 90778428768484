// Generic AJAX header and query structure
function query(url, token) {
  return (
    fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': token
      }
    })
      .then(res => res.json())
      .then(res => res.data));
}

function queryWithMethod(url, token, method) {
  return (
    fetch(url, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': token
      }
    }));
}

export { query, queryWithMethod }
