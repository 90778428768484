const toggleEventView = (festivalButton, allStateButton) => {
  const festivalList = document.querySelector('.festival-js');
  const allStateList = document.querySelector('.all-state-js');

  if (festivalButton.checked) {
    festivalList.classList.remove('hidden');
    allStateList.classList.add('hidden');
  }

  if (allStateButton.checked) {
    festivalList.classList.add('hidden');
    allStateList.classList.remove('hidden');
  }
}

// Toggle between Festival and All-State Event views
document.addEventListener('DOMContentLoaded', async (event) => {
  const festivalButton = document.getElementById('festival-radio') as HTMLInputElement;
  const allStateButton = document.getElementById('all-state-radio') as HTMLInputElement;

  if (festivalButton) {
    toggleEventView(festivalButton, allStateButton);
    festivalButton.addEventListener('change', () => toggleEventView(festivalButton, allStateButton));
    allStateButton.addEventListener('change', () => toggleEventView(festivalButton, allStateButton));
  }
});