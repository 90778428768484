import { debounce } from './helpers';
import { query } from './queries';

function allStateSearchBar(input, dataList, record, altRecord = null) {
  let csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
  let entries;

  debounce(async function() {
    while (dataList.firstChild) {
      dataList.removeChild(dataList.firstChild)
    }

    if (altRecord) {
      entries = await query(`/admins/all_state_${altRecord}/${record}s/suggested_names?name=${input.value}`, csrfToken);
    } else {
      entries = await query(`/admins/all_state/${record}s/suggested_names?name=${input.value}`, csrfToken);
    }

    entries.map(entry => {
      const option = document.createElement('option');
      option.value = entry.first_name + " " + entry.last_name;
      dataList.appendChild(option);
    });
  }, 250)();
}

function festivalSearchBar(input, dataList, record) {
  let csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  debounce(async function() {
    while (dataList.firstChild) {
      dataList.removeChild(dataList.firstChild)
    }

    let entries = await query(`/admins/festivals/${record}/suggested_names?name=${input.value}`, csrfToken);

    entries.map(entry => {
      const option = document.createElement('option');
      option.value = entry.first_name + " " + entry.last_name;
      dataList.appendChild(option);
    });
  }, 250)();
}

export { allStateSearchBar, festivalSearchBar }
