import { query } from '../queries';

// Retrieve correct composition categories based on student grade
document.addEventListener('change', async (event) => {
  const target = event.target as HTMLInputElement;

  if (target.getAttribute('data-action') === 'composition-grade-select') {
    const grade = target.value;
    const dataTarget = target.getAttribute('data-target');
    const el: HTMLSelectElement = document.querySelector(dataTarget);

    if (!el) return console.error('Invalid target provided for composition-grade-select');

    el.innerHTML = null;

    if (!grade) {
      el.disabled = true;
      return;
    }

    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    const categories = await query(`/composition_categories/${grade}`, csrfToken);

    // blank by default
    const option = document.createElement('option');
    option.value = "";
    option.innerText = "Select Category";
    el.appendChild(option);

    categories.map(category => {
      const option = document.createElement('option');
      option.value = category[1]
      option.innerText = category[0];
      el.appendChild(option);
    });

    el.disabled = false;
  }
});


