// // Find all elements with the "alert" class and remove them
// // from the DOM after a certain period of time.

const TIME_UNTIL_REMOVED = 3500; // 3.5 seconds

document.addEventListener('DOMContentLoaded', () => {
  const allAlerts = document.querySelectorAll('.alert');
  const exitAlert = document.querySelector('.exit-alert');
  const numberInputs = document.querySelectorAll('.number-input-js');

  const closeAlert = () => {
    Array.from(allAlerts).map(el => el.remove());
  };

  const removeAlert = () => {
    Array.from(allAlerts).map(el => el.classList.add('leaving'));

    setTimeout(function() {
      Array.from(allAlerts).map(el => el.remove())
    }, 9500);
  }

  if (exitAlert) {
    exitAlert.addEventListener('click', removeAlert);
  }

  setTimeout(removeAlert, TIME_UNTIL_REMOVED);
});



