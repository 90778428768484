import { query } from '../queries';

document.addEventListener('change', async (event) => {
  const target = event.target as HTMLInputElement;
  const validActions = ['audition-ensemble-select', 'audition-grade-select', 'audition-type-select', 'add-student-school-grade-select'];

  // Retrieve and set ensemble, grade, and type
  if (validActions.includes(target.getAttribute('data-action'))) {
    const districtId = (<HTMLInputElement>document.getElementById('nomination_district_id')).value;
    const grade_input = document.getElementById('audition_grade');
    const chorus_input = document.getElementById('audition_secondary_chorus')
    const ensemble_input = document.getElementById('audition_ensemble');
    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    let audition_type_input = document.getElementById('audition_audition_type');
    let flexibleDistricts, ensemble, grade, audition_type = null;

    if (target.getAttribute('data-action') === 'audition-ensemble-select') {
      ensemble = target.value;
      grade = (<HTMLInputElement>grade_input).value;
      const dataTarget = target.getAttribute('data-alt-target');
      const el: HTMLSelectElement = document.querySelector(dataTarget);

      if (audition_type_input) {
        audition_type = (<HTMLInputElement>audition_type_input).value;
      }
      if (ensemble == "elementary" || (ensemble == 'vocal_jazz' && districtId == "6" && (grade == '7' || grade == '8'))) {
        document.getElementById('audition-ranking').classList.remove('hidden-rank');
      } else {
        document.getElementById('audition-ranking').classList.add('hidden-rank');
      }

      if (ensemble == 'chorus') {
        if (grade == '7' || grade == '8') {
          if (target.getAttribute('data-junior-chorus') === 'true') {
            document.querySelector('.secondary-chorus-js').classList.remove('hidden');
          }

          while (el.firstChild.nextSibling) {
            el.removeChild(el.firstChild.nextSibling);
          }

          let parts = null;
          parts = await query(`/audition_parts/secondary_chorus?district_id=${districtId}&grade=${grade}`, csrfToken);

          parts.map(part => {
            const option = document.createElement('option');
            option.value = part;
            option.innerText = part;
            el.appendChild(option);
          });
        }
        else if (grade == '9' || grade == '10') {
          document.querySelector('.secondary-chorus-js').classList.remove('hidden');

          while (el.firstChild.nextSibling) {
            el.removeChild(el.firstChild.nextSibling);
          }

          let parts = null;
          parts = await query(`/audition_parts/secondary_chorus?district_id=${districtId}&grade=${grade}`, csrfToken);

          parts.map(part => {
            const option = document.createElement('option');
            option.value = part;
            option.innerText = part;
            el.appendChild(option);
          });
        }
        else {
          document.querySelector('.secondary-chorus-js').classList.add('hidden');
          (document.querySelector('#audition_secondary_chorus') as HTMLSelectElement).value = "";

        }
      } else {
        document.querySelector('.secondary-chorus-js').classList.add('hidden');
        (document.querySelector('#audition_secondary_chorus') as HTMLSelectElement).value = "";
      }
    }

    if (target.getAttribute('data-action') === 'audition-grade-select') {
      grade = target.value;
      const dataTarget = target.getAttribute('data-alt-target-two');
      const el: HTMLSelectElement = document.querySelector(dataTarget);

      if (ensemble_input) {
        if (ensemble_input.getAttribute('data-alt-value')) {
          ensemble = (<HTMLInputElement>ensemble_input).getAttribute('data-alt-value');
        } else {
          ensemble = (<HTMLInputElement>ensemble_input).value;
        }

        if (ensemble == 'chorus') {
          if (grade == '7' || grade == '8') {
            document.querySelector('.secondary-chorus-js').classList.remove('hidden');

            while (el.firstChild.nextSibling) {
              el.removeChild(el.firstChild.nextSibling);
            }

            let parts = null;
            parts = await query(`/audition_parts/secondary_chorus?district_id=${districtId}&grade=${grade}`, csrfToken);

            parts.map(part => {
              const option = document.createElement('option');
              option.value = part;
              option.innerText = part;
              el.appendChild(option);
            });
          } else if (grade == '9' || grade == '10') {
            document.querySelector('.secondary-chorus-js').classList.remove('hidden');

            while (el.firstChild.nextSibling) {
              el.removeChild(el.firstChild.nextSibling);
            }

            let parts = null;
            parts = await query(`/audition_parts/secondary_chorus?district_id=${districtId}&grade=${grade}`, csrfToken);

            parts.map(part => {
              const option = document.createElement('option');
              option.value = part;
              option.innerText = part;
              el.appendChild(option);
            });
          }
          else {
            document.querySelector('.secondary-chorus-js').classList.add('hidden');
            (document.querySelector('#audition_secondary_chorus') as HTMLSelectElement).value = "";
          }
        }
      } else {
        document.querySelector('.secondary-chorus-js').classList.add('hidden');
        (document.querySelector('#audition_secondary_chorus') as HTMLSelectElement).value = "";
      }

      if (audition_type_input) {
        audition_type = (<HTMLInputElement>audition_type_input).value;
      }
    }

    // Some 9th grade students can choose between senior/junior ensembles
    if (target.getAttribute('data-action') === 'audition-type-select') {
      audition_type = target.value;
      grade = (<HTMLInputElement>grade_input).value;

      if (ensemble_input.getAttribute('data-alt-value')) {
        ensemble = (<HTMLInputElement>ensemble_input).getAttribute('data-alt-value');
      } else {
        ensemble = (<HTMLInputElement>ensemble_input).value;
      }
    }

    if (document.querySelector('.type-js')) {
      if (grade == "9") {
        flexibleDistricts = await query(`/audition_parts/districts`, csrfToken);

        if (flexibleDistricts && flexibleDistricts.includes(parseInt(districtId))) {
          document.querySelector('.type-js').classList.remove('hidden');
        }
      } else {
        document.querySelector('.type-js').classList.add('hidden');
        audition_type = null;
        (<HTMLInputElement>audition_type_input).value = "";
      }
    }

    if (target.getAttribute('data-action') !== 'add-student-school-grade-select') {
      const dataTarget = target.getAttribute('data-target');
      const el: HTMLSelectElement = document.querySelector(dataTarget);

      if (!el) return console.error(`Invalid target provided for ${target.getAttribute('data-action')}`);
      el.innerHTML = null;

      if (document.querySelector('.type-js') && !document.querySelector('.type-js').classList.contains('hidden')) {
        if (!ensemble || !grade || !audition_type) {
          el.disabled = true;
          return;
        }
      } else {
        if (!ensemble || !grade) {
          el.disabled = true;
          return;
        }
      }

      // Choral entries require a student height
      if (ensemble == 'chorus' || ensemble == 'vocal_jazz' || ensemble == 'elementary') {
        document.querySelector('.height-js').classList.remove('hidden');
      } else {
        if (document.querySelector('.height-js')) {
          document.querySelector('.height-js').classList.add('hidden');
          (document.querySelector('#audition_feet') as HTMLSelectElement).value = "";
          (document.querySelector('#audition_inches') as HTMLSelectElement).value = "";
        }
      }

      // Given ensemble and grade, we can determine which parts a student is eligible for
      let parts = null;

      if (audition_type) {
        parts = await query(`/audition_parts/index?district_id=${districtId}&grade=${grade}&ensemble=${ensemble}&audition_type=${audition_type}`, csrfToken);
      } else {
        parts = await query(`/audition_parts/index?district_id=${districtId}&grade=${grade}&ensemble=${ensemble}`, csrfToken);
      }

      // blank by default
      const option = document.createElement('option');
      option.value = "";
      option.innerText = "Please select a part...";
      el.appendChild(option);

      parts.map(part => {
        const option = document.createElement('option');
        option.value = part[1];
        option.innerText = part[0];
        el.appendChild(option);
      });

      el.disabled = false;
    }
  }
});
