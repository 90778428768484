import { debounce } from './helpers';
import { query } from './queries';

// Student searchbar
document.addEventListener('DOMContentLoaded', async (event) => {
  const nameInput = document.querySelector('.student-search-js') as HTMLInputElement;
  const dataList = document.querySelector('datalist#student-search') as HTMLDataListElement;
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  if (nameInput) {
    const school = nameInput.dataset.schoolId;
    const suggestedNames = debounce(async function() {
      while (dataList.firstChild) {
        dataList.removeChild(dataList.firstChild)
      }

      const students = await query(`/educator/schools/${school}/students/suggested_names?name=${nameInput.value}`, csrfToken);

      students.map(student => {
        const option = document.createElement('option');
        option.value = student.first_name + " " + student.last_name;
        dataList.appendChild(option);
      });
    }, 250);

    nameInput.addEventListener('keyup', suggestedNames);
    nameInput.addEventListener('change', suggestedNames);
  }
});
