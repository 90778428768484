import { query } from '../queries';

// Retrieve events based on grade for Festival Event filter
document.addEventListener('change', async (event) => {
  const target = event.target as HTMLInputElement;

  if (target.getAttribute('data-action') === 'grade-select') {
    const grade = target.value;
    const dataTarget = target.getAttribute('data-target');
    const el: HTMLSelectElement = document.querySelector(dataTarget);

    if (!el) return console.error('Invalid target provided for grade-select');

    el.innerHTML = null;
    if (!grade) { return }

    const district = document.querySelector("[name='search[district_id]']") as HTMLSelectElement;

    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    const events = await query(`/admins/events?district_id=${district.value}&participation_level=${grade}`, csrfToken);

    // blank by default
    const option = document.createElement('option');
    option.value = "";
    option.innerText = "Select Event";
    el.appendChild(option);

    events.map(event => {
      const option = document.createElement('option');
      option.value = event[1];
      option.innerText = event[0];
      el.appendChild(option);
    });
  }
});
