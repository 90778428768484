import { query } from './queries';

// List a school's educators
document.addEventListener('change', async (event) => {
  const target = event.target as HTMLInputElement;

  if (target.getAttribute('data-action') === 'district-school-select') {
    const schoolId = target.value;

    const dataTarget = target.getAttribute('data-alt-target');
    const el: HTMLSelectElement = document.querySelector(dataTarget);

    if (!el) return console.error('Invalid target provided for school-select');

    el.innerHTML = null;

    if (!schoolId) {
      el.disabled = true;
      return;
    }

    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    const educators = await query(`/schools/${schoolId}/educators`, csrfToken);

    // blank by default
    const option = document.createElement('option');
    option.value = "";
    option.innerText = "Select Educator";
    el.appendChild(option);

    educators.map(educator => {
      const option = document.createElement('option');
      option.value = educator[1];
      option.innerText = educator[0];
      el.appendChild(option);
    });

    el.disabled = false;
  }
});
