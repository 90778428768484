document.addEventListener('DOMContentLoaded', () => {
  const radioButtons = document.querySelectorAll('.judge-approval-button-js');

  // Approve Judge by District
  if (radioButtons) {
    radioButtons.forEach(radioButton => {
      radioButton.addEventListener('click', e => {
        let eventTarget = e.target as HTMLInputElement;
        eventTarget.form.submit();
      });
    })
  }
});

