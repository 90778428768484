import { query } from '../queries';

document.addEventListener('change', async (event) => {
  const target = event.target as HTMLInputElement;

  // Allow access to form inputs once a school is selected
  if (target.getAttribute('data-action') === 'add-student-school-select') {
    document.querySelectorAll('.district-disabled-js').forEach(input => input.removeAttribute('disabled'));
  }

  // Additionally, retrieve the grades served by a school
  if (target.getAttribute('data-action') === 'add-student-elementary-all-state-school-select' ||
    target.getAttribute('data-action') === 'add-student-school-grade-select') {
    const schoolId = target.value;
    const dataTarget = target.getAttribute('data-alt-target');
    const el: HTMLSelectElement = document.querySelector(dataTarget);

    document.querySelectorAll('.district-disabled-js').forEach(input => input.removeAttribute('disabled'));

    if (!el) return console.error('Invalid target provided');
    el.innerHTML = null;

    if (!schoolId) {
      el.disabled = true;
      return;
    }

    const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');
    const grades = await query(`/admins/schools/schools/grades?school_id=${schoolId}`, csrfToken);

    // blank by default
    const option = document.createElement('option');
    option.value = "";
    option.innerText = "Select Grade";
    el.appendChild(option);

    grades.map(grade => {
      const option = document.createElement('option');
      option.value = grade;
      option.innerText = grade;
      el.appendChild(option);
    });

    el.disabled = false;
  }
});
