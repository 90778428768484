import * as React from 'react';

import StripeWrapper from './stripe/Wrapper';
import StripeStyles from './stripe/styles';
import StripeForm from './stripe/Form';

interface Props {
  path: string;
}

class PaymentParticipation extends React.Component<Props> {
  render() {
    return (
      <StripeWrapper>
        <div className="card detailed card--form">
          <StripeForm styles={StripeStyles} path={this.props.path} />
        </div>
      </StripeWrapper>
    );
  }
}

export default PaymentParticipation;
