import { debounce } from './helpers';
import { query } from './queries';

// Educator Searchbar
document.addEventListener('DOMContentLoaded', async (event) => {
  const educatorNameInput = document.querySelector('.educator-search-js') as HTMLInputElement;
  const dataList = document.querySelector('datalist#educator-search') as HTMLDataListElement;
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  if (educatorNameInput) {
    const school = educatorNameInput.dataset.schoolId;
    const suggestedNames = debounce(async function() {
      while (dataList.firstChild) {
        dataList.removeChild(dataList.firstChild)
      }

      const educators = await query(`/admins/educators/educators/suggested_names?name=${educatorNameInput.value}`, csrfToken);

      educators.map(educator => {
        const option = document.createElement('option');
        option.value = educator.first_name + " " + educator.last_name;
        dataList.appendChild(option);
      });
    }, 250);

    educatorNameInput.addEventListener('keyup', suggestedNames);
    educatorNameInput.addEventListener('change', suggestedNames);
  }
});
