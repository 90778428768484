import { debounce } from './helpers';
import { query } from './queries';

// Elementary All-State Chorus Searchbar
document.addEventListener('DOMContentLoaded', async (event) => {
  const elementaryAllStateNameInput = document.querySelector('.elementary-all-state-search-js') as HTMLInputElement;
  const dataList = document.querySelector('datalist#elementary-all-state-search') as HTMLDataListElement;
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  if (elementaryAllStateNameInput) {
    const school = elementaryAllStateNameInput.dataset.schoolId;
    const suggestedNames = debounce(async function() {

      while (dataList.firstChild) {
        dataList.removeChild(dataList.firstChild)
      }

      const students = await query(`/educator/schools/${school}/students/suggested_elementary_all_states?name=${elementaryAllStateNameInput.value}`, csrfToken);

      students.map(student => {
        const option = document.createElement('option');
        option.value = student.first_name + " " + student.last_name;
        dataList.appendChild(option);
      });
    }, 250);

    elementaryAllStateNameInput.addEventListener('keyup', suggestedNames);
    elementaryAllStateNameInput.addEventListener('change', suggestedNames);
  }
});
