import { queryWithMethod } from './queries';

// Update participation status of a school
document.addEventListener('DOMContentLoaded', async () => {
  const paymentInputs = document.querySelectorAll('.admin__billing__checkbox_js');

  paymentInputs.forEach(box => {
    let checkbox = box as HTMLInputElement;

    checkbox.addEventListener('change', async (event) => {
      const target = event.target as HTMLInputElement;
      const entry = target.dataset.entry;
      const record = target.dataset.record;
      const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

      if (checkbox.checked) {
        const events = await queryWithMethod(`/admins/payments/payments/${entry}?paid=true&record=${record}`, csrfToken, 'put');
        if (events.status !== 200) {
          checkbox.nextElementSibling.classList.add('error__border_red');
        } else {
          checkbox.nextElementSibling.classList.remove('error__border_red');
        }
      } else {
        const events = await queryWithMethod(`/admins/payments/payments/${entry}?paid=false&record=${record}`, csrfToken, 'put');
        if (events.status !== 200) {
          checkbox.nextElementSibling.classList.add('error__border_red');
        } else {
          checkbox.nextElementSibling.classList.remove('error__border_red');
        }
      }

    });
  });
});
