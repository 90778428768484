import * as React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

class StripeWrapper extends React.Component {
  state = { apiKey: null }

  componentDidMount() {
    const apiKey = document
      .querySelector('meta[name="stripe-public-key"]')
      .getAttribute('content');

    this.setState({ apiKey });
  }
  render() {
    return this.state.apiKey && (
      <StripeProvider apiKey={this.state.apiKey}>
        <Elements>
          {this.props.children}
        </Elements>
      </StripeProvider>
    );
  }
}

export default StripeWrapper;
